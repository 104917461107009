import React, { memo } from 'react';

import SCNaeLogoItem from './SCNaeLogoItem';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const SCNaeLogos = ({colorSelected, logos, itemClass, onClick}) => {
    return (
        <>
            {logos.map((logo) => (
                <div key={logo.id} data-id={logo.id} {...className(`logo-item ${itemClass ? `logo-item--${itemClass}`: ''}`)} >
                    <SCNaeLogoItem 
                        colorBg='3'
                        colorIcon='1' 
                        onClick={onClick(logo)}
                        logo={logo} 
                        colorSelected={colorSelected} 
                    /> 
                </div>
            ))}
        </>
    )
}

const areEqualLogos = (prev, next) => {
    const colorSelectedId = !prev.colorSelected ? -1 : prev.colorSelected.id;
    const nextColorSelectedId = !next.colorSelected ? -1 : next.colorSelected.id;

    if (prev.itemClass !== next.itemClass) {
        return false;
    }
    else if (colorSelectedId !== nextColorSelectedId) {
        return false   
    } else {
        return true
    }
}

export default memo(SCNaeLogos, areEqualLogos);