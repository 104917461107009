import React, { Component, createRef } from 'react';
import shuffle from 'lodash.shuffle';
import isSharedComponent from '../../hoc/isSharedComponent';

import { Row, Col } from '../Bootstrap/Grid';
import Button from '../Bootstrap/Button';
import SCNaeLogoPreview from './SCNaeLogoPreview';
import SCNaeColorItem from './SCNaeColorItem';
import SCNaeLogos from './SCNaeLogos';
import AlertNotCoincidence from './Alert/AlertNotCoincidence';

import { matchAllVariablesUrl } from '../../utils';
import BCEvents from '../../modules/BCEvents/BCEvents';

import strings from './strings.json';

import sadFace from '../../assets/sad-face.svg';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

let Isotope = null;
if (typeof window !== `undefined`) {
    Isotope = require("isotope-layout/js/isotope");
}

BCEvents.create('SCNaeLogoGenerator-apply');
BCEvents.create('SCNaeLogoGenerator-setup');
BCEvents.create('SCNaeLogoGenerator-allowClick');
BCEvents.create('SCNaeLogoGenerator-disallowClick');

const formatArrayToObject = (array, key = 'id', value = null) => {
    let newObj = {};
    array.forEach(item => {
        newObj[item[key]] = value ? item[value] : {};
    });
    return newObj;
}

const intersectionObjects = (obj1, obj2) => {
    let newObj = {};
    Object.keys(obj2).forEach(id => {
        if (obj1[id]) {
            newObj[id] = obj1[id] + obj2[id];
        }
    });
    return newObj;
}

const additionObjects = (obj1, obj2) => {
    let newObj = {...obj1};
    Object.keys(obj2).forEach(id => {
        if (newObj[id]) {
            newObj[id] = newObj[id] + obj2[id];
        } else {
            newObj[id] = obj2[id];
        }
    });
    return newObj;
}


const SCNaeLogoGeneratorHeader = ({ title = null, subtitle = null, component = null, ...props }) => (
    <div {...className(`header pt-4 pb-3 ${props.className ? props.className : ''}`)}>
        {component ? component : (
            <>
                {title ? <h6 {...className('mb-1')}>{title}</h6> : null}
                {subtitle ? <p {...className('mb-0')}>{subtitle}</p> : null}
            </>
        )}
    </div>
)
class SCNaeLogoGenerator extends Component {
    constructor(props) {
        super(props);

        this.formattedTags = formatArrayToObject(props.naeConcepts, 'id');

        this.state = {
            tags: [],
            logos: {},
            colors: {},
            shouldRender: 0,
            shouldFilter: 0,
            showPreview: false,
            showAddMsgLogos: false,
            showAddMsgColors: false,
            isotopeLoaded: false,
            logoSelected: null,
            logosFiltered: [],
            colorSelected: null,
            ...(typeof window !== 'undefined' && window.location.hash && !props.isEditMode) && this.getStateFromUrl()
        }

        this.shuffledLogos = shuffle(props.naeLogos);

        this.isoLogos = null;
        this.isoColors = null;
        this.wrapperLogosRef = createRef();
        this.wrapperColorsRef = createRef();
        this.previewLogoRef = createRef();
        this.isSafariMobile = this.checkSafariMobile();
        
        this.generateDownloadUrl = this.generateDownloadUrl.bind(this);
        this.getStateFromUrl = this.getStateFromUrl.bind(this);
        this.updateStateFromUrl = this.updateStateFromUrl.bind(this);
        this.updateIsotope = this.updateIsotope.bind(this);
        this.combineTags = this.combineTags.bind(this);
        this.hidePreview = this.hidePreview.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.changeLogoSelected = this.changeLogoSelected.bind(this);
        this.handleLogoClose = this.handleLogoClose.bind(this);
        this.handleBackResults = this.handleBackResults.bind(this);
        this.handleDownloadSafariMobile = this.handleDownloadSafariMobile.bind(this);
        this.getFilteredString = this.getFilteredString.bind(this);
        this.getString = this.getString.bind(this);
    }

    componentDidMount() {
        if (!this.isoLogos && this.wrapperLogosRef.current) {
            this.isoLogos = new Isotope(this.wrapperLogosRef.current, {
                layoutMode: 'fitRows',
                getSortData: {
                    id: '[data-id] parseInt',
                    relation: this.sortFnc('logos')
                }
            });
            //this.isoLogos.layout();
        }

        if (!this.isoColors && this.wrapperColorsRef.current) {
            this.isoColors = new Isotope(this.wrapperColorsRef.current, {
                layoutMode: 'fitRows',
                getSortData: {
                    id: '[data-id]',
                    relation: this.sortFnc('colors')
                }
            });
            //this.isoColors.layout();
        }

        if (typeof window !== `undefined` && !this.props.isEditMode) {
            window.addEventListener('hashchange', this.updateStateFromUrl);
        }

        if (typeof window !== `undefined` && !this.props.isEditMode && window.location.hash) {
            this.updateIsotope();
            this.setState((state) => ({
                    logosFiltered: this.isoLogos.getFilteredItemElements().map(item => item.dataset.id),
                    isotopeLoaded: true,
                    shouldRender: state.shouldRender + 1
                })
            );
        } else {
            this.setState((state) => ({
                isotopeLoaded: true,
                shouldRender: state.shouldRender + 1
            })
        );
        }

        BCEvents.subscribe('SCNaeLogoGenerator-apply', 'SCNaeLogoGenerator', this.handleDownloadSafariMobile);
    }

    componentDidUpdate(prevProps, prevState) {
        const { shouldFilter, showPreview, colorSelected } = this.state;
        const { isEditMode, urlDownload } = this.props;

        if (!(prevState.showPreview && !showPreview) && !isEditMode) {
            if (!window.location.hash && this.generateUrl() === '#') {
// Fast and ugly fix
            } else {
                window.location.href = this.generateUrl();
                this.sendAnalytics('hashchange', this.generateUrl());
            }
        }
        if (shouldFilter > 0 && prevState.shouldFilter !== shouldFilter) {
            this.updateIsotope();
        }

        // TODO: Trigger only when it changes
        BCEvents.trigger(showPreview && colorSelected && urlDownload ? 'SCNaeLogoGenerator-allowClick' : 'SCNaeLogoGenerator-disallowClick');
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.shouldRender !== nextState.shouldRender;
    }

    componentWillUnmount() {
        if (typeof window !== `undefined` && !this.props.isEditMode) {
            window.removeEventListener('hashchange', this.updateStateFromUrl);
        }

        BCEvents.unsubscribe('SCNaeLogoGenerator-apply', 'SCNaeLogoGenerator');
    }

    checkSafariMobile () {
        if (typeof window !== `undefined`) {
            const ua = window.navigator.userAgent;
            const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
            const webkit = !!ua.match(/WebKit/i);
            return iOS && webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i) && !ua.match(/EdgiOS/i);
        } else {
            return null;
        }
    }

    getStateFromUrl(state = null) {
        const regexHash = /(#)([A-Z]*)([0-9]*)([A-Z]?)/;
        const { 2: tagsIds, 3: logoId, 4: colorId } = regexHash.exec(window.location.hash);
        
        const newTags = tagsIds.split('');
        let newLogo = null;
        let newColor = null;
        const numberLogoId = parseInt(logoId);

        if (numberLogoId && numberLogoId > 0) {
            newLogo = this.props.naeLogos.find(el => el.id === numberLogoId);
        }
        if (colorId) {
            newColor = this.props.naeColors.find(el => el.id === colorId);
        }

        const stateTags = this.updateStateTags(state, newTags, colorId);
        let existLogo = false;

        if (Object.keys(stateTags.logos).length) {
            existLogo = stateTags.logos && Object.keys(stateTags.logos).includes(logoId);
        } else if (!tagsIds) {
            if (newLogo) {
                existLogo = true;
            } 
        }

        return {
            ...newColor && { colorSelected: newColor },
            ...newTags.length > 0 && stateTags,
            ...newLogo && existLogo && { logoSelected: newLogo, showPreview: true },
        }
    }

    updateStateFromUrl() {
        if (window.location.hash && window.location.hash !== this.generateUrl()) {
            this.setState((state) => {
                return this.getStateFromUrl(state);
            });
        }
    }

    updateIsotope() {
        this.isoLogos.layout();
        this.isoLogos.updateSortData();
        this.isoColors.updateSortData();
        this.isoLogos.arrange({ filter: this.filterFnc('logos'), sortBy: ['relation', 'id'], sortAscending: { relation: false, id: true} });
        this.isoColors.arrange({ filter: this.filterFnc('colors'), sortBy: ['relation', 'id'], sortAscending: { relation: false, id: true} });
    }

    generateUrl(newLogo = null, addHash = true) {
        const { tags, logoSelected, colorSelected } = this.state;
        const logo = newLogo || logoSelected;
        return `${addHash ? '#' : ''}${tags.sort().join('')}${logo ? logo.id : (colorSelected ? '0' : '')}${colorSelected ? colorSelected.id : ''}`;
    }

    generateDownloadUrl() {
        const { urlDownload } = this.props;
        const {logoSelected, colorSelected} = this.state;

        if (!colorSelected || !logoSelected || !urlDownload || !matchAllVariablesUrl(urlDownload).every(el => ['${logoId}', '${colorId}'].includes(el))) {
            return null;
        }
        let newUrl = urlDownload;
        matchAllVariablesUrl(urlDownload).forEach(el => {
            const value = el.includes('logo') ? logoSelected.id : colorSelected.id;
            newUrl = newUrl.replace(el, value);
        });
        return newUrl;
    }

    filterFnc = (to) => (itemElem) => {
        const id = itemElem.dataset.id;
        return Object.keys(this.state[to]).length === 0 || this.state[to][id] !== undefined; 
    }

    sortFnc = (to) => (itemElem) => {
        const id = itemElem.dataset.id;
        return Object.keys(this.state[to]).length === 0 || this.state[to][id] === undefined ? 1 : parseInt(this.state[to][id]); 
    }

    combineTags (newTags = [], to = 'logos') {
        const { naeConcepts } = this.props;
     
        let newObject = null;
        let isAddition = false;
        if (newTags.length > 0) {
            newTags.forEach( tag => {
                if (!this.formattedTags[tag][to]) {
                    const arrayTags = naeConcepts.find(item => item.id === tag)[to];
                    this.formattedTags[tag][to] = formatArrayToObject(arrayTags, 'id', 'r');
                }
                if (!newObject) {
                    newObject = this.formattedTags[tag][to];
                } else {
                    newObject = intersectionObjects(newObject, this.formattedTags[tag][to]);
                }
            });
            if ( typeof newObject === 'object' && Object.keys(newObject).length === 0) {
                isAddition = true;
                let addObject = null;

                newTags.forEach( tag => {
                    if (!addObject) {
                        addObject = this.formattedTags[tag][to];
                    } else {
                        addObject = additionObjects(addObject, this.formattedTags[tag][to]);
                    }
                });
                newObject = addObject;
            }
        } else {
            newObject = {}    
        }

        return { object: newObject, isAddition };
    }
    
    handleColorChange = (color) => (e) => {
        e.preventDefault();
        this.setState((state) => ({
            colorSelected: state.colorSelected && state.colorSelected.id === color.id ? null : color,
            shouldRender: state.shouldRender + 1
        }));
    }

    handleLogoSelected = (logo) => (e) => {
        e.preventDefault();
        this.setState((state) => ({
            showPreview: true,
            logoSelected: logo,
            logosFiltered: this.isoLogos.getFilteredItemElements().map(item => item.dataset.id),
            shouldRender: state.shouldRender + 1
        }));
    }

    changeLogoSelected(newLogo) {
        window.location.href = this.generateUrl(newLogo);
        
        this.setState(state => ({
            logoSelected: newLogo,
            shouldRender: state.shouldRender + 1
        }));
    }

    setUpLogoNavbar = (savedLogo) => {
        const { logoSelected, colorSelected } = this.state;

        BCEvents.trigger('SCNaeLogoGenerator-setup', savedLogo);  
        this.sendAnalytics('applyLogo', `#${logoSelected.id}${colorSelected.id}`);
    }

    sendAnalytics (action, label) {
        if (typeof window !== `undefined` && window.ga) {
            window.ga('send', {
                hitType: 'event',
                eventCategory: 'SCNaeLogoGenerator',
                eventAction: action,
                eventLabel: label,
                eventValue: 1
            });
        }
    }
        
    handleLogoClose() {
        this.setState((state) => ({
            logoSelected: null,
            logosFiltered: null,
            shouldRender: state.shouldRender + 1
        }));
    }

    hidePreview () {
        this.setState((state) => ({
            showPreview: false,
            shouldRender: state.shouldRender + 1
        }));
    }

    handleBackResults(e) {
        e.preventDefault();
        this.hidePreview();
        if (this.previewLogoRef && this.previewLogoRef.current) {
            this.previewLogoRef.current.closePreview();
        }     
    }

    handleDownloadSafariMobile() {
        const { naeCommas } = this.props;

        const savedLogo = {
            logo: this.state.logoSelected,
            color: this.state.colorSelected,
            comma: naeCommas && naeCommas.length ? naeCommas.find(c => c.id === this.state.logoSelected.id) : {}
        };

        this.setUpLogoNavbar(savedLogo);
    }

    updateStateTags(state, tags, colorId = null) {
        const logos = this.combineTags(tags);
        const colors = this.combineTags(tags, 'colors');
        
        const arrayColors = Object.keys(colors.object);
        const existColor = arrayColors.length > 0 ? arrayColors.includes(colorId) : true;

        return {
            tags: tags,
            logos: logos.object,
            colors: colors.object,
            showPreview: false,
            showAddMsgLogos: logos.isAddition,
            showAddMsgColors: colors.isAddition,
            ...state && { shouldRender: state.shouldRender + 1, shouldFilter: state.shouldFilter + 1 },
            ...!existColor && {colorSelected: null}
        };
    }

    toggleTag = (tag) => (e) => {
        e.preventDefault();
        if (this.previewLogoRef && this.previewLogoRef.current) {
            this.previewLogoRef.current.closePreview();
        }
        this.setState((state) => {
            let newTags;
            if (state.tags.includes(tag.id)) {
                newTags = [...state.tags.filter(el => el !== tag.id)];
            } else {
                newTags = [...state.tags, tag.id];
            }
            const colorSelectedId = state.colorSelected ? state.colorSelected.id : null;
            return this.updateStateTags(state, newTags, colorSelectedId);
        });
    }

    getFilteredString(filterFor = 'logos') {
        const { tags, logos, colors } = this.state; 

        const filteredItemsLength = tags.length && logos && Object.keys(logos).length ? Object.keys(logos).length : null;
        const filteredColorsLength = tags.length && colors && Object.keys(colors).length ? Object.keys(colors).length : null;

        if (filteredItemsLength || filteredColorsLength) {
            if (filterFor === 'logos' && filteredItemsLength) {
                return this.getString('logo.subtitle', filteredItemsLength);
            } else if (filterFor === 'colors' && filteredColorsLength) {
                return this.getString('color.subtitle', filteredColorsLength);
            }
        }
           
        return this.getString('logo.subtitle.placeholder');
    }

    getString(key, length = null) {
        const lang = this.props.lang ? this.props.lang : 'es';
        let string = strings[key] && strings[key][lang] ? strings[key][lang] : key;

        if (length !== null && string.indexOf('||') !== -1) {
            let stringArr = string.split('||');
            if (length === 1) {
                return stringArr[0];
            }

            return stringArr[1].replace('{d}', length);
        }

        return string;
    }

    render() {
        const { naeColors, naeConcepts, naeLogos, urlDownload, lang } = this.props;
        const { tags, logos, colorSelected, showAddMsgLogos, showAddMsgColors, showDownloadMsg, logoSelected, logosFiltered, showPreview } = this.state;

        let itemClass = null;
        const numberLogos = Object.keys(logos).length;
        if (numberLogos && numberLogos <= 40) {
            itemClass = 5;
        };
        if (numberLogos && numberLogos <= 24) {
            itemClass = 4;
        };
        if (numberLogos && numberLogos <= 12) {
            itemClass = 3;
        };
        if (numberLogos && numberLogos <= 6) {
            itemClass = 2;
        };
        if (numberLogos && numberLogos <= 1) {
            itemClass = 1;
        };
        
        return (
            <Row {...className('wrapper h-100')}>
                <Col col={3} {...className(`wrapper-tags px-3 pb-4 px-xl-6 border-right with-scroll`)}>
                    <SCNaeLogoGeneratorHeader
                        title={this.getString('tags.title')}
                        subtitle={tags.length ? this.getString('tags.subtitle', tags.length) : this.getString('tags.subtitle.placeholder')}
                    />
                    <div {...className('container-tags')}>
                        {naeConcepts.map((item, i) => (
                            <Button 
                                key={i}
                                tag="button"
                                {...className('d-inline-block', null, 'classNameDiv')}
                                {...className(`tag-item ${tags.includes(item.id) ? 'selected' : ''}`)} 
                                color="primary"
                                outline={!tags.includes(item.id)}
                                onClick={this.toggleTag(item)}
                            >{item.concept}</Button>
                        ))}
                    </div>
                </Col>
                <Col col={6} {...className(`p-0 position-relative h-100`)}>
                    <div {...className('px-7 px-xl-8 px-xxl-9 pb-8 position-relative with-scroll')}>
                        <SCNaeLogoGeneratorHeader
                            title={this.getString('logo.title')}
                            subtitle={this.getFilteredString()}
                        />

                        {showAddMsgLogos && 
                            <AlertNotCoincidence icon={sadFace} text={this.getString('logo.notresults')} otherText={this.getString('logo.otherresults')} />
                        }

                        <div ref={this.wrapperLogosRef} {...className(`container-logos ${this.state.isotopeLoaded ? 'container-logos--show': ''}`)}>
                            <SCNaeLogos
                                logos={this.shuffledLogos}
                                colorSelected={colorSelected}
                                onClick={this.handleLogoSelected}
                                itemClass={itemClass}
                            />
                        </div>
                    </div>
                    {logoSelected && logosFiltered.length > 0 ? (
                        <SCNaeLogoPreview
                            ref={this.previewLogoRef}
                            logoSelected={logoSelected} 
                            logos={naeLogos} 
                            logosFiltered={logosFiltered}
                            colorSelected={colorSelected}
                            handleLogoClose={this.handleLogoClose}
                            changeLogoSelected={this.changeLogoSelected}
                            handleBackResults={this.handleBackResults}
                            getString={this.getString}
                        />
                    ) : null}
                </Col>
                <Col col={3} {...className('px-3 pb-4 px-xl-6 border-left with-scroll')}>
                    <SCNaeLogoGeneratorHeader
                        title={this.getString('color.title')}
                        subtitle={this.getFilteredString('colors')}
                    />

                    {showAddMsgColors && 
                        <AlertNotCoincidence text={this.getString('color.notresults')} otherText={this.getString('logo.otherresults')} />
                    }
                    <div ref={this.wrapperColorsRef} {...className(`container-colors`)}>
                        {naeColors.map(color => (
                            <div key={color.id} data-id={color.id} {...className(`color-wrapper`)} >
                                <SCNaeColorItem 
                                    key={color.id}
                                    color={color}
                                    onClick={this.handleColorChange(color)}
                                    active={colorSelected && colorSelected.id === color.id} 
                                />
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        );
    }
};

export default isSharedComponent(SCNaeLogoGenerator, 'SCNaeLogoGenerator');