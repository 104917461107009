import React from 'react';
import { Container } from '../Bootstrap/Grid';
import SCRow from './SCRow';
import isSharedComponent from '../../hoc/isSharedComponent';

const SCSection = ({ id, pt, pb, rows, bgColor = '', isFluid = false, lang = null }) => (
    <Container isFluid={isFluid} {...{ id, pt, pb }} {...bgColor && { style: { position: 'relative' }}}>
        {bgColor && 
            <Container style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0}}>
                <div style={{backgroundColor: `var(--${bgColor})`, height: '100%', width: '100%' }}></div>
            </Container>
        }
        {rows && rows.length > 0 && rows.map((item, i) => (
            <SCRow key={i} sectionId={id} rowIndex={i} lang={lang} {...item} />
        ))}
    </Container>
);

export default isSharedComponent(SCSection, 'SCSection');