import React from 'react';
import parse from 'html-react-parser';
import isSharedComponent from '../../hoc/isSharedComponent';
import Alert from '../Bootstrap/Alert';
import { generatePaddingClasses } from '../../utils';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);


const SCImage = ({ children, legendText = '', shadowSize = '', pt, pb, showLegend, legendColor = 'danger', extraClass = '' }) => {

    let classes = [
        `container-fluid`,
        showLegend ? 'wrapper-with-legend' : 'wrapper-without-legend'
    ];

    if (shadowSize) {
        classes.push(`${shadowSize === 'normal' ? 'shadow' : 'shadow-' + shadowSize}`);
    } else {
        classes.push(`shadow-none`);
    }

    classes = generatePaddingClasses(pt, 't', classes, 'm');
    classes = generatePaddingClasses(pb, 'b', classes, 'm');  

    if (extraClass) {
        classes.push(extraClass);
    }
    
    return (
        <div {...className(classes.join(' '))}>
            {children}
            {showLegend &&
                <Alert color={legendColor} extraClass={'mt-2 mb-0 border-0 rounded-0'}>
                    { (typeof legendText === 'string') ? parse(legendText) : legendText }
                </Alert>
            }
        </div>
    );
};

export default isSharedComponent(SCImage, 'SCImage');