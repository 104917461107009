import React, { useEffect } from 'react';

import Layout from './parts/Layout';

import SCSection from '../../components/SCGrid/SCSection';

import { setupColors, setupPrevious, removeColors } from './userconfig';

import './index.module.scss';
import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const NaeTemplate = ({ Pages, currentPage, layout, lang, indexPath }) => {
	const navigatePages = Pages ? Pages.filter(p => !p.layout).concat(Pages.filter(p => p.layout)) : [];
	
	useEffect(() => {
		const fixHeight = () => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
		fixHeight();
		window.addEventListener('resize', fixHeight);

		return () => { 
			window.removeEventListener('resize', fixHeight);
		};
	}, []);

	useEffect(() => {
		if (currentPage && Object.keys(currentPage).length) {
			if (currentPage.layout === 'full-width') {
				setupPrevious();
				removeColors();
			} else {
				setupColors();
			}
		}
	}, [ currentPage ]);
	
	return (
			<Layout
				layout={layout} 
				currentPage={currentPage} 
				navigatePages={navigatePages}
				lang={lang}
				indexPath={indexPath}
			>
				{currentPage && currentPage.sections ? currentPage.sections.map((section, i) => {
					if (!section) {
						return null;
					}
					
					return (
						<section {...className(currentPage && currentPage.layout === 'full-width' ? 'section-full' : 'section')} key={i} id={section.id}>
							<SCSection isFluid={currentPage && currentPage.layout === 'full-width'} lang={lang} {...section} />
						</section>
					)
				}) : null}
			</Layout>
		)
};

export default NaeTemplate;
