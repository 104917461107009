import React, { memo } from 'react';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const SCNaeColorItem = ({color, active = false, ...props }) => {
    return (
        <div {...className(`color-fix-height`)}>
            <a 
                {...className(`color-list d-flex align-items-center justify-content-center pointer ${active ? 'color-list--active':''}`)}
                href='#'
                {...props}
                >
                <div {...className(`position-relative w-100 h-100 rounded-circle overflow-hidden`)}>
                    {[1, 2, 3].map(colorKey => (
                        <div {...className(`color-item--${colorKey}`)} key={`${color.id}.${colorKey}`} style={{ backgroundColor: color[`c${colorKey}`] }} />
                    ))}
                </div>
            </a>
        </div>
    )
}

export default memo(SCNaeColorItem);