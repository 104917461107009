import React from 'react';
import isSharedComponent from '../../hoc/isSharedComponent';

import Alert from '../Bootstrap/Alert';

import { generatePaddingClasses } from '../../utils';

import CssClassNames from '../../scss/CssClassNames';
const { className } = new CssClassNames();

const SCAlert = ({color, pt, pb, extraClass, children, content}) => {

    let classes = [];

    classes = generatePaddingClasses(pt, 't', classes);
    classes = generatePaddingClasses(pb, 'b', classes);
    
    return (
        <div {...className(`${classes.join(' ')}`)}>
            <Alert 
                color={color} 
                extraClass={extraClass ? extraClass : 'm-0'}
                children={children}
                content={content}
            /> 
        </div>
    );
};

export default isSharedComponent(SCAlert, 'SCAlert');