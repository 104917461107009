import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import Template from 'aliasTemplate';


const getNodes = rawCollection => (
    rawCollection.edges.reduce((data, edge) => ({ ...data, [edge.node.id]: { ...edge.node } }), {})
)

const IndexPage = ({ pageContext, path }) => (
	<StaticQuery
        query={graphql`
			{
				brandCenterVersion {
					slug
				}
				allBrandCenterVersionMenu {
					edges {
						node {
							active
							slug
							published
							name
							id 
						}
					}
				}
				brandCenterTemplate {
					values {
						headerTitle
						headerSubtitle
						btnHeaderText
						headerLogo {
							value
							kind
						}
		
						sidebarTitle
		
						footerText
						footerTitle
						footerHtml {
							value
							kind
						}
						footerCopy
					}
				}
			}
        `}
        render={({
			allBrandCenterVersionMenu,
			brandCenterTemplate,
			brandCenterVersion
        }) => {
			const {
				id = null,
				layout = null,
				pagesData,
				allBrandCenterSection,
				currentPage = null,
				lang = null,
				seo = {},
				indexPath = '/'
			} = pageContext;
			
			return (
				<Layout pageSeo={seo} path={path}>
					<Template 
						id={id}
						lang={lang}
						layout={layout}
						versionSlug={brandCenterVersion.slug}
						brandCenterTemplate={brandCenterTemplate}
						versionsMenu={getNodes(allBrandCenterVersionMenu)}
						Pages={pagesData}
						currentPage={currentPage}
						Sections={allBrandCenterSection}
						indexPath={indexPath}
					/>
				</Layout>
			);
		}}
	/>
);

export default IndexPage;