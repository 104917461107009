import React, { memo } from 'react';
import parse from 'html-react-parser';
import striptags from 'striptags';

import CssClassNames from '../../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const Alert = memo(({ content = 'Alert text', children, color = 'primary', mt, mb, extraClass = ''}) => {

    const contentFiltered = striptags(content, ['a', 'b', 'i', 'u', 's', 'strong', 'em', 'br']);

    return (
        <div {...className(`alert alert-${color} 
            ${mt ? `mt-${mt}`: ''}
            ${mb ? `mb-${mb}`: ''}
            ${extraClass ? extraClass : ''}
        `)} 
        role="alert">
            {children || parse(contentFiltered)}
        </div>
    );
});

export default Alert;