import React from 'react';

import DownloadButton from '../DownloadButton';

import { TYPO_PLACEHOLDER } from '../../variables';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);


const Typography = ({ name, fontFamily = null, variants = [], text, tag, href, gatsbyFile }) => {

    const fontStyle = {
        fontFamily: fontFamily,
        fontStyle: 'normal',
    }
    
    const firstActiveVariant = variants.find(el => el.active);
    const lastActiveVariant = [...variants].reverse().find(el => el.active);

    return (
        <div {...className(`d-flex flex-wrap`)} >

            <div {...className(`font-display`)}>
                <span {...className(`font-display-letter`)}
                    {...(fontFamily && lastActiveVariant ? { style: { ...fontStyle, fontWeight: lastActiveVariant.weight } } : '')}
                >
                    Aa
                </span>
                <h5 {...className(`font-display-title`)}
                    {...(fontFamily && firstActiveVariant ? { style: { ...fontStyle, fontWeight: firstActiveVariant.weight } } : '')}
                >
                    {name || 'No font'}
                </h5>
                {href &&
                    <DownloadButton text='DOWNLOAD' {...{ tag, href, gatsbyFile }} />
                }
            </div>

            <div {...className(`font-variants`)}
                {...(fontFamily ? { style: fontStyle } : '')}
            >
                {variants && variants.filter(el => el.active).map(fontVariant => (
                    <div key={fontVariant.weight} {...className('font-variants-item')}>
                        <p {...className('font-variants-title')}
                            style={{ fontWeight: fontVariant.weight }}
                        >
                            {`${name} ${fontVariant.weight}`}
                        </p>
                        {(text || TYPO_PLACEHOLDER).split(/(?:\r\n|\r|\n)/g).filter(txt => (!!txt)).map((txt, i) => (
                            <p key={i} style={{ fontWeight: fontVariant.weight }}>
                                {txt}
                            </p>
                        ))}
                    </div>
                ))}
            </div>

        </div>
    );
};

export default Typography;