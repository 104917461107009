import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';

import SCNaeLogoItem from './SCNaeLogoItem';

import Button from '../Bootstrap/Button';
import SVGArrow from './SVGArrow';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

let timerFade = null;

const SCNaeLogoPreview = ({logosFiltered = [], logoSelected, logos, colorSelected, handleLogoClose, changeLogoSelected, handleBackResults, getString }, ref) => {

    const [show, setShow] = useState(false);
    const [logo, setLogo] = useState({
        object: logoSelected,
        index: logosFiltered.findIndex(el => parseInt(el) === logoSelected.id)
    });
 
    useEffect(() => {
        if (logo.object.id !== logoSelected.id) {
            setLogo({
                object: logoSelected,
                index: logosFiltered.findIndex(el => parseInt(el) === logoSelected.id)
            })
        }
    },[logoSelected.id]);

    useEffect(() => {
        if (show) {
            changeLogoSelected(logo.object);
        }
    }, [logo.index]);


    const handleChangeItem = (direction) => (e) => {
        e.preventDefault();
        let newLogo = null;
        setLogo(prevLogo => {
            if (prevLogo.index + direction < 0 || (prevLogo.index + direction) > (logosFiltered.length - 1) ) {
                return prevLogo;
            }
            newLogo = logos.find(el => el.id === parseInt(logosFiltered[prevLogo.index + direction]));

            return  {
                object: newLogo,
                index: prevLogo.index + direction
            }
        });
    }

    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
        }
        setShow(false);
        timerFade = setTimeout(() => {
            handleLogoClose();
        }, 300);
    }

    useEffect(() => {
        setShow(true);

        const keyListener = (e) => {
            switch(e.keyCode) {
                case 37:
                    handleChangeItem(-1)(e);
                    break;
                case 39:
                    handleChangeItem(+1)(e);
                    break;
                default:
                    return;
            }
        }
        window.addEventListener('keydown', keyListener);

        return () => {
            setShow(false);
            window.removeEventListener('keydown', keyListener);
            if (timerFade) {
                clearTimeout(timerFade);
            }
        }
    }, []);

    useImperativeHandle(ref, () => ({
        getLogoSelected: () => {
            return logo.object
        },
        closePreview: handleClose
    }));

    return (
        <div {...className(`wrapper-preview p-0 d-flex flex-column ${show ? 'show' : ''} with-scroll`)}>
            <div {...className('px-7 pt-3')}>
                <Button 
                    color="primary"
                    outline={true}
                    size="sm"
                    tag="button"
                    onClick={handleBackResults}
                    {...className('back-button')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
                        <path fill="#0082FF" fillRule="evenodd" d="M6.403 0l1.053.971L2.825 5.31l-.78.706 5.455 5.01L6.452 12 0 6.002z"/>
                    </svg>
                    <span {...className('ml-1')}>{getString('preview.back')}</span>
                </Button>
            </div>
            <div {...className('px-7 px-xl-12 py-8 preview-container d-flex flex-column')}>
                <SCNaeLogoItem  
                    {...className('preview-logo mb-2')} 
                    colorBg='3'
                    colorIcon='1'
                    logo={logo.object} 
                    colorSelected={colorSelected} 
                />
                <div {...className('preview-small d-flex')}>
                    <SCNaeLogoItem  
                        {...className('preview-logo--small mr-2')}
                        colorBg='1'
                        colorIcon='3'
                        logo={logo.object} 
                        colorSelected={colorSelected} 
                    /> 
                    <SCNaeLogoItem  
                        {...className('preview-logo--small')}
                        colorBg='2'
                        colorIcon='1'
                        logo={logo.object} 
                        colorSelected={colorSelected} 
                    /> 
                </div>
            </div>
            <div {...className('preview-actions px-7 py-3 border-top w-100 d-flex justify-content-between')}>
                {logo.index > 0 ? ( 
                    <Button 
                        tag="button"
                        color="link"
                        size="lg"
                        onClick={handleChangeItem(-1)}
                        {...className('preview-actions-btn pl-0 d-flex align-items-center')} 
                    >
                        <SVGArrow />
                        <span {...className('ml-2')}>{getString('preview.previous')}</span>
                    </Button>
                ) : <div />}
                {logo.index < logosFiltered.length - 1 ? (
                    <Button 
                        tag="button"
                        color="link"
                        size="lg"
                        onClick={handleChangeItem(+1)}
                        {...className('preview-actions-btn pr-0 d-flex align-items-center')} 
                    >
                        <span {...className('mr-2')}>{getString('preview.next')}</span>
                        <SVGArrow rotate="180deg" />
                    </Button>
                ) : <div />}
            </div>
        </div>
    )
}

export default forwardRef(SCNaeLogoPreview);