import React from 'react';

import isSharedComponent from '../../hoc/isSharedComponent';
import ColorCard from './ColorCard';
import { generatePaddingClasses } from '../../utils';

import { Row, Col } from '../Bootstrap/Grid';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const SCNaeColor = ({naePalettes = [], pt, pb}) => {
    let classes = [];

    classes = generatePaddingClasses(pt, 't', classes);
    classes = generatePaddingClasses(pb, 'b', classes);

    return (
        <div {...className(`${classes.join(' ')}`)}>
            <Row>
                {naePalettes.map((color, i) => (
                    <Col key={color.id || i} col={{xs: 12, lg: 6}}>
                        <ColorCard {...color} />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default isSharedComponent(SCNaeColor, 'SCNaeColor');