import React from 'react';

const SvgComponent = ({color = '#0082FF', rotate = null, props}) => (
  <svg width={20} height={16} {...props} {...rotate && {style: {transform: `rotate(${rotate})`}}}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M7.82 0l1.285 1.295L3.449 7.08H20v1.838H3.463l5.696 5.784L7.879 16 0 8.002z"
    />
  </svg>
)

export default SvgComponent
