import React from 'react';
import isSharedComponent from '../../hoc/isSharedComponent';

import Button from '../Bootstrap/Button';

const SCLinkButton = (props) => {
    
    return(
        <Button {...{tag: props.tag, ...props}} />
    )
} 

export default isSharedComponent(SCLinkButton, 'SCLinkButton');
