import React from 'react';
import renderSCItemsByType from '../../renderSCItemsByType';
import isSharedComponent from '../../hoc/isSharedComponent';
import { Col } from '../Bootstrap/Grid';
import { getCssProp } from '../../scss/CssClassNames';

const SCCol = ({ objectPath, width, offset, order, display, items, lang }) => (
    <Col className={getCssProp({ col: width, offset, order, display }, null, { display: 'd' })} >
        {renderSCItemsByType(objectPath, items, { lang })}
    </Col>
);

export default isSharedComponent(SCCol, 'SCCol');