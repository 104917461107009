import React from 'react';
import isSharedComponent from '../../hoc/isSharedComponent';
import { Row, Col } from '../Bootstrap/Grid';
import SCCol from './SCCol';
 
const SCRow = ({ sectionId, rowIndex, pt, pb, bgColor = '', cols, lang }) => (
    <Row {...{pt, pb}} {...bgColor && {style: { position : 'relative'}}}>
        {bgColor && 
            <Col col={12} style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0}}>
                <div style={{backgroundColor: `var(--${bgColor})`, height: '100%', width: '100%' }}></div>
            </Col>
        }
        {cols && cols.length > 0 && cols.map((item, colIndex) => {
            const objectPath = { colIndex, rowIndex, sectionId };
            return (<SCCol key={JSON.stringify(objectPath)} {...{ ...item, objectPath, lang }} />)
        })}
    </Row>
);

export default isSharedComponent(SCRow, 'SCRow');