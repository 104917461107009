import React, {memo} from 'react';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const SCNaeLogoItem = ({logo, colorSelected, onClick = null, className: extraClass, colorIcon, colorBg, ...props}) => {

        const Tag = onClick ? 'a' : 'div';

       return  (
           <Tag 
                {...className(`${extraClass} svg-wrapper border rounded ${Tag === 'a' ? 'd-block':''}`)}
                {...onClick && {onClick}}
                {...Tag === 'a' && {href: '#'}}
                {...colorSelected && {style: {backgroundColor: colorSelected[`c${colorBg}`]}}}
                {...props}
           >
                <div {...className(`d-flex h-100 w-100 align-items-center`)}>
                    <svg {...className('logo')} width={`${logo.w}px`} height={`${logo.h}px`} viewBox={`0 0 ${logo.w} ${logo.h}`}>
                         <path fill={colorSelected ? colorSelected[`c${colorIcon}`] : '#000000'} fillRule="evenodd" d={logo.p} />
                    </svg>
                </div>
           </Tag>
        )
}

export default memo(SCNaeLogoItem);
