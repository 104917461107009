import React, { memo } from 'react';
import ColorCardItem from './ColorCardItem';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const ColorCard = (props) => {

    let items = [];
    for (let i = 1; i < 5; i++) {
        items.push(<ColorCardItem key={i} {...props[`c${i}`]} />);
    }

    return (
        <div {...className(`d-flex justify-content-center mb-3 w-100 position-relative`)} >
            {items}
        </div>
    );
}

export default memo(ColorCard);