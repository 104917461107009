import React from 'react';
import Alert from '../../Bootstrap/Alert';

import CssClassNames from '../../../scss/CssClassNames';
import styles from '../styles.module.scss';;
const { className } = new CssClassNames(styles);

const AlertNae = ({icon = null, text = null, ...props}) => {
    return (
        <Alert {...props} >
            <div {...className('d-flex align-items-center alert-inner')}>
                {icon && <img {...className('mr-2')} src={icon} alt='icon' />}
                {text && <p {...className('text-primary mb-0')}>{text}</p>}
            </div>
        </Alert>
    )
}

export default AlertNae;