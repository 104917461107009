import React from 'react'


import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

export default function Arrow({direction = 'next', disabled = false, onClickArrow}) {
    return (
        <button onClick={onClickArrow(direction)} {...className(`${styles['btn-slider']} ${disabled ? styles.disabled : ''} ${direction === 'next' ? styles['arrow-right'] : styles['arrow-left']}`)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                <path fill="#0082FF" fillRule="evenodd" d="M12.18 0l-1.285 1.295 5.656 5.785H0v1.838h16.537l-5.696 5.784L12.121 16 20 8.002z"/>
            </svg>
        </button>
    )
}
