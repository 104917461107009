import React, {memo} from 'react';
import AlertNae from './AlertNae';

import CssClassNames from '../../../scss/CssClassNames';
import styles from '../styles.module.scss';;
const { className } = new CssClassNames(styles);

const AlertNotCoincidence = ({icon = null, text = null, otherText = null, ...props}) => {
    return (
        <div {...className('mb-3')}>
            <AlertNae mb={6} text={text} {...icon && {icon}} {...props} />
            {otherText ? (<p {...className('text-primary')}>{otherText}</p>) : null}
        </div>
    )
}

export default memo(AlertNotCoincidence)