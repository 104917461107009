import React from 'react';

export const renderItem = (objectPath, item, otherProps = {}) => {
    const key = JSON.stringify(objectPath);
    
    let Item = null;
    switch (item.type) {
        case 'br':
            return (
                <br key={key} />
            );
        case 'unknown_schema_type':
            return null;
        case 'heading':
            Item = require('./components/SCHeading').default;
        break;
        case 'alert':
            Item = require('SCAlert').default;
        break;
        case 'image':
            Item = require('./components/SCImage').default;
        break;
        case 'link_button':
            Item = require('SCLinkButton').default;
        break;
        case 'copy_signature_button':
            Item = require('SCCopySignatureButton').default;
        break;
        case 'download_button':
            Item = require('SCDownloadButton').default;
        break;
        case 'color':
            Item = require('SCColor').default;
        break;
        case 'typography':
            Item = require('SCTypography').default;
        break;
        case 'titles':
            Item = require('SCTitles').default;
        break;
        case 'signature':
            Item = require('SCSignature').default;
        break;
        case 'nae_logo_generator':
            Item = require('SCNaeLogoGenerator').default;
        break;
        case 'nae_panel':
            Item = require('SCNaePanel').default;
        break;
        case 'nae_texts':
            Item = require('SCNaeTexts').default;
        break;
        case 'nae_slider':
            Item = require('SCNaeSlider').default;
        break;
        case 'nae_typography':
            Item = require('SCNaeTypography').default;
        break;
        case 'nae_color':
            Item = require('SCNaeColor').default;
        break;
        case 'nae_download_button':
            Item = require('SCNaeDownloadButton').default;
        break;
        case 'video_embed':
            Item = require('SCVideoEmbed').default;
        break;
        default:
        break;
    }
    
    if (Item) {
        return (
            <Item 
                key={key}
                objectPath={objectPath}
                {...otherProps}
                {...item}
            />
        );
    }

    return (
        <span key={key} style={{ color: "Red" }}>{item.type} unknown type</span>
    );
};


const renderSCItemsByType = (objectPath, items, otherProps) => (
    items && items.length > 0 && items.map((item, itemIndex) => (
        renderItem({ itemIndex, ...objectPath }, item, otherProps)
    ))
);

export default renderSCItemsByType;