import React from 'react';
import isSharedComponent from '../../hoc/isSharedComponent';

import DownloadButton from '../DownloadButton';

const SCDownloadButton = (props) => (
    <DownloadButton {...props} />
);

export default isSharedComponent(SCDownloadButton, 'SCDownloadButton');
