export const BREAKPOINTS =  {
    'xxl':  1440,
    'xl':   1200,
    'lg':   992,
    'md':   768,
    'sm':   576,
    'xs':   0
}

export const TITLES_ITEMS = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p: 'Body',
    lead: 'Lead',
    display1: 'Display 1',
    display2: 'Display 2',
    display3: 'Display 3',
    display4: 'Display 4'
}

export const TYPO_PLACEHOLDER = `A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
a b c d e f g h i j k l m n o p q r s t u v w x y z
0 1 2 3 4 5 6 7 8 9 ! @ # $ % ^ & * ( )
`;