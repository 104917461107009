import React from 'react';

import parse from 'html-react-parser';
import striptags from 'striptags';

import isSharedComponent from '../../hoc/isSharedComponent';

import { Row, Col } from '../Bootstrap/Grid';
import { generatePaddingClasses } from '../../utils';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const SCNaePanel = ({pt, pb, title = '', text, showComma, relation, position, isInversed, children }) => {
    const textFiltered = striptags(text, ['a', 'b', 'i', 'u', 's', 'strong', 'em', 'br']);

    const [, x, y] = (/([1-3])_([1-3])/gm).exec(relation);
    const [, posY, posX] = (/([^_]+)_([^_]+)/gm).exec(position);

    let classes = [];
    classes = generatePaddingClasses(pt, 't', classes);
    classes = generatePaddingClasses(pb, 'b', classes);

    return (
        <div {...className(`${classes.join(' ')}`)}>
            <div {...className(`${styles.wrapper} ${isInversed ? styles.inversed : ''}`)}>
                <Row {...className(`position-relative ${posY === 'top' ? `padBottom-row-${y}`: `padTop-row-${y}`}`)}>
                    <Col {...className(`${styles.content} pt-10 pb-8 pb-lg-7 pt-lg-8 pb-xl-8 py-xxl-10`)} offset={posX === 'right' ? parseInt(x)+1 : { xs: 0, lg: 1 }} col={{ xs: 12 - parseInt(x) - 1, lg: 12-parseInt(x)-2 }}>
                        {title && 
                            <h2 {...className(`${styles.title} mb-6 mb-lg-8 md-xl-9 md-xxl-10`)}>
                                <span {...className(`position-relative ${showComma ? styles['title-comma'] : ''}`)}>
                                    {title}
                                </span>
                            </h2>
                        }
                        {children || parse(textFiltered)}
                    </Col>
                    <Col 
                        {...posX === 'right' && { offset: x }} 
                        col={12-parseInt(x)}
                        {...className(`
                            h-100 
                            position-absolute 
                            ${posY === 'top' ? `box-top padBottom-${y}`: `box-bottom padTop-${y}`}
                            ${posX === 'left' ? 'box-left' : 'box-right'}
                        `)}>
                        <div {...className(`${styles.box} h-100`)} />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default isSharedComponent(SCNaePanel, 'SCNaePanel');