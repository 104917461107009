import React, { memo } from 'react'

import { getContrastColor } from '../../utils';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

 const ColorCardItem = (({color, text}) => {

    return (
        <div style={{backgroundColor: color}} {...className(`${styles['card-item']} position-relative`)}>
            <span style={{color: getContrastColor(color)}} {...className(`position-absolute`)}>{text}</span>
        </div>
    )
});

export default memo(ColorCardItem);