import React from 'react';

import Typography from '../Typography';
import isSharedComponent from '../../hoc/isSharedComponent';
import { Row, Col } from '../Bootstrap/Grid';
import { generatePaddingClasses } from '../../utils';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);


const SCNaeTypography = ({ pt, pb, position, relation, ...props }) => {

    const [, x, y] = (/([1-3])_([1-3])/gm).exec(relation);
    const [, posY, posX] = (/([^_]+)_([^_]+)/gm).exec(position);

    let classes = [];

    classes = generatePaddingClasses(pt, 't', classes);
    classes = generatePaddingClasses(pb, 'b', classes);

    return (
        <div {...className(`${classes.join(' ')}`)} >
            <div {...className(`${styles.wrapper}`)}>
                <Row {...className(`position-relative ${posY === 'top' ? `padBottom-row-${y}`: `padTop-row-${y}`}`)}>
                    <Col {...posX === 'right' && { offset: x }} col={12-parseInt(x)}  {...className(`h-100 position-absolute ${posY === 'top' ? `box-top padBottom-${y}`: `box-bottom padTop-${y}`}`)}>
                        <div {...className(`${styles.box} w-100 h-100`)} />
                    </Col>
                    <Col {...className(`${styles.content} py-8 py-xxl-10`)} offset={posX === 'right' ? parseInt(x)+1 : 1 } col={12-parseInt(x)-2}>
                        <Typography {...props} />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default isSharedComponent(SCNaeTypography, 'SCNaeTypography');